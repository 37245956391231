import React, {
  memo,
  MouseEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAnalytics } from '@czechtv/analytics-react';
import { IconChevronLeft } from '@czechtv/icons';
import { CaptionColorVariant, CaptionFontSize } from '../../../../utils/closedCaptions';
import { usePlayerContext } from '../../../PlayerContext';
import { PlayerSubtitles } from '../../../../Providers/Client';
import { PickerItem } from '../../../../components/Picker/Picker';
import { usePlayerSetup } from '../../../../Providers/Setup/usePlayerSetup';
import { getAudioLanguageLabel } from '../../../../utils/getAudioLanguageLabel';
import { AudioTrackSelectOptions, PlayerAudioTrack } from '../../../../utils/types';
import MenuPopup from '../MenuPopup';
import {
  LANG_CODE_AD,
  MenuPopupType,
  PlayerVariantEnum,
  SETTINGS_MESSAGES,
  SETTINGS_OTHER_MESSAGES,
  FAQ_URL,
} from '../../../../constants';
import { useMediaBreakpoints } from '../../../../utils/playerResponsive';
import { FaqLink } from '../../../../components/FaqLink/FaqLink';
import { getResolutionLabel } from '../../../../utils/getResolutionLabel';
import { CaptionsStyleList } from '../CaptionsStyle/CaptionsStyleList';
import { settingsMenuClassnames } from './SettingsMenu.css';
import {
  SettingsListItemProps,
  SettingsListItemsEnum,
} from './SettingsList/SettingsListItem/SettingsListItem';
import { SettingsList } from './SettingsList/SettingsList';

// nové menu /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// nastavení vzhledu titulků  - ticket
// předělání automatických testů - ticket na michala k.
// oprava unit testů
// TODO desktop a mobile velikostni varianty tlacitek, paddingy etc - ticket

export interface SettingsMenuProps {
  adaptiveResolution: number | null;
  audioTracks: PlayerAudioTrack[] | null;
  availableResolutions: number[];
  isVideoOnly?: boolean;
  onAudioTrackSelected: (options: AudioTrackSelectOptions) => void;
  onClose?: () => void;
  onForceAudioOnlySwitch?: () => void;
  onPause?: () => void;
  onResolutionSelected: (resolution: number | null) => void;
  onTextTrackSelected: (textTrack: PlayerSubtitles | null) => void;
  playerRef?: RefObject<HTMLElement>;
  selectedAudioTrack?: PlayerAudioTrack | null;
  selectedResolution?: number | null;
  selectedTextTrack?: PlayerSubtitles | null;
  settingsMenuRef?: RefObject<HTMLDivElement>;
  textTracks: PlayerSubtitles[] | null;
}

export const getResolutionLabelForAnalytics = (resolution: number | null): string => {
  if (resolution) {
    return `${getResolutionLabel(resolution)} - ${resolution}p`;
  }
  if (resolution === null) {
    return 'Auto';
  }
  return '';
};

const SettingsMenu = ({
  adaptiveResolution,
  availableResolutions,
  audioTracks,
  settingsMenuRef,
  textTracks,
  onAudioTrackSelected: onAudioTrackSelectedCallback,
  onTextTrackSelected: onTextTrackSelectedCallback,
  onResolutionSelected,
  selectedAudioTrack: selectedPlayerAudioTrack,
  selectedTextTrack: selectedPlayerSubtitles,
  selectedResolution,
  playerRef,
  isVideoOnly,
  onPause = () => {},
  onClose = () => {},
  onForceAudioOnlySwitch = () => {},
}: SettingsMenuProps) => {
  const { isMaxSmallMobile } = useMediaBreakpoints();
  const {
    setMenuPopupVisible,
    playbackRate,
    setPlaybackRate,
    selectedChromecastSubtitles,
    selectedChromecastAudioTrack,
    isChromecastSession,
    forcedAudioOnly,
    isNewPlaylist,
    setCaptionColorVariant,
    setCaptionFontSize,
    captionColorVariant,
    captionFontSize,
    playlistOptions,
  } = usePlayerContext();
  const { playerClient, playerVariant } = usePlayerSetup();
  const analytics = useAnalytics();
  const shortNames = isMaxSmallMobile;
  const DEFAULT_TITLE = 'Nastavení';

  const handleAudioOnlySwitch = useCallback(() => {
    onForceAudioOnlySwitch();
  }, [onForceAudioOnlySwitch]);

  const selectedAudioTrack = isChromecastSession
    ? selectedChromecastAudioTrack
    : selectedPlayerAudioTrack;
  const selectedTextTrack = isChromecastSession
    ? selectedChromecastSubtitles
    : selectedPlayerSubtitles;

  const isExternalAudioDescription = selectedAudioTrack?.language === LANG_CODE_AD;

  const onTextTrackSelected = useCallback(
    (textTrackItem: PickerItem) => {
      const textTrack = (textTracks || []).find(
        (_textTrack) => _textTrack.url === textTrackItem.key
      );
      if (selectedTextTrack && selectedTextTrack.code === textTrack?.code) {
        return;
      }
      onTextTrackSelectedCallback(textTrack || null);
    },
    [onTextTrackSelectedCallback, textTracks, selectedTextTrack]
  );

  const onAudioTrackSelected = useCallback(
    (audioTrackItem: PickerItem) => {
      const audioTrack = Array.from(audioTracks || []).find(
        (_audioTrack) => _audioTrack.id === audioTrackItem.key
      );
      onAudioTrackSelectedCallback({ audioTrack: audioTrack || null });
    },
    [audioTracks, onAudioTrackSelectedCallback]
  );

  const onResolutionSelectedFactory = useCallback(
    (resolution: SettingsListItemProps) => {
      // nechceme nic delat, rozliseni ma pouze orientacni charakter
      if (availableResolutions.length < 2) {
        return;
      }
      onResolutionSelected(parseInt(resolution.label));
      analytics.trigger({ type: 'PlayerSettingsChangeResolution' });
    },
    [analytics, onResolutionSelected, availableResolutions]
  );

  const onPlaybackRateSelected = useCallback(
    (playbackRateItem: SettingsListItemProps) => {
      const newPlaybackRate = Number(playbackRateItem.key);
      setPlaybackRate(newPlaybackRate);
      playerClient.onPlaybackRateChange(newPlaybackRate);
      analytics.trigger({ type: 'PlayerSettingsChangePlaybackRate' });
    },
    [playerClient, setPlaybackRate, analytics]
  );

  const onCaptionsSizeSelected = useCallback(
    (captionSize: SettingsListItemProps) => {
      setCaptionFontSize(captionSize.key as CaptionFontSize);
    },
    [setCaptionFontSize]
  );

  const onCaptionsColorSelected = useCallback(
    (captionColor: SettingsListItemProps) => {
      setCaptionColorVariant(captionColor.key as CaptionColorVariant);
    },
    [setCaptionColorVariant]
  );

  const onHelpClick = useCallback(() => {
    onPause();
    analytics.trigger({ type: 'PlayerSettingsErrorReport' });
  }, [analytics, onPause]);

  const disabledTextTrackItem = useMemo(() => {
    return {
      key: SETTINGS_OTHER_MESSAGES.disabledTextTrackItem.defaultMessage,
      label: SETTINGS_OTHER_MESSAGES.disabledTextTrackItemLabel.defaultMessage,
      active: !selectedTextTrack,
    };
  }, [selectedTextTrack]);

  const subtitlesNew: SettingsListItemProps[] = useMemo(
    () => [
      disabledTextTrackItem,
      ...(textTracks || []).map((textTrack) => ({
        key: textTrack.url,
        label: textTrack.title || '',
        active: textTrack.url === selectedTextTrack?.url,
      })),
    ],
    [disabledTextTrackItem, selectedTextTrack?.url, textTracks]
  );

  const audios: SettingsListItemProps[] = useMemo(
    () =>
      Array.from(audioTracks || [])
        .filter((audioTrack) => {
          // pro Chromecast audioDescription nenabizime, odfiltrujeme z menu
          if (audioTrack.language === LANG_CODE_AD && isChromecastSession) {
            return false;
          }
          return true;
        })
        .map((audioTrack) => {
          return {
            key: audioTrack.id,
            label: getAudioLanguageLabel(audioTrack, shortNames),
            active: audioTrack.id === selectedAudioTrack?.id,
          };
        }),
    [audioTracks, isChromecastSession, selectedAudioTrack, shortNames]
  );

  const playbackRates: SettingsListItemProps[] = useMemo(
    () =>
      [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2].map((rate) => {
        const label = !shortNames && rate === 1 ? 'Normální' : `${rate}x`;
        return {
          key: String(rate),
          label,
          active: rate === playbackRate,
        };
      }),
    [shortNames, playbackRate]
  );

  const availableResolutionsList: SettingsListItemProps[] = useMemo(() => {
    const auto = {
      key: 'auto',
      label: 'Auto',
      // pro hls nezobrazujeme description
      description: availableResolutions.length ? `${adaptiveResolution}p` : '',
      active:
        selectedResolution === null ||
        typeof selectedResolution === 'undefined' ||
        Number.isNaN(selectedResolution) ||
        isExternalAudioDescription,
    };
    const available = !isExternalAudioDescription
      ? availableResolutions.map((resolution) => {
          const label = `${resolution}p`;
          return {
            key: String(resolution),
            label,
            active: resolution === selectedResolution,
            description: getResolutionLabel(parseInt(label)),
          };
        })
      : [];
    return [...available, auto];
  }, [adaptiveResolution, availableResolutions, isExternalAudioDescription, selectedResolution]);
  // slozime strukturu seznamu polozek
  const settingsListItems = useMemo(() => {
    const showAudioOnlySwitch = isNewPlaylist && !isChromecastSession && !isVideoOnly;

    const baseItems: SettingsListItemProps[] = [
      ...(!forcedAudioOnly && !isChromecastSession
        ? [
            {
              key: SettingsListItemsEnum.QUALITY,
              label: SettingsListItemsEnum.QUALITY,
              valuesList: availableResolutionsList,
              onSelect: onResolutionSelectedFactory,
              testId: 'playerSettingsResolutionContainer',
            },
          ]
        : []),
      ...(audios.length > 1 && !isVideoOnly
        ? [
            {
              key: SettingsListItemsEnum.AUDIO,
              label: SettingsListItemsEnum.AUDIO,
              valuesList: audios,
              onSelect: onAudioTrackSelected,
              testId: 'playerSettingsAudioContainer',
            },
          ]
        : []),
      ...(subtitlesNew.length > 1
        ? [
            {
              key: SettingsListItemsEnum.SUBTITLES,
              label: SettingsListItemsEnum.SUBTITLES,
              valuesList: subtitlesNew,
              onSelect: onTextTrackSelected,
              testId: 'playerSettingsSubtitlesContainer',
            },
          ]
        : []),
      ...(playerVariant === PlayerVariantEnum.VOD
        ? [
            {
              key: SettingsListItemsEnum.PLAYBACK_RATE,
              label: SettingsListItemsEnum.PLAYBACK_RATE,
              valuesList: playbackRates,
              onSelect: onPlaybackRateSelected,
              testId: 'playerSettingsPlaybackRateContainer',
            },
          ]
        : []),
      ...(showAudioOnlySwitch
        ? [
            {
              key: SettingsListItemsEnum.AUDIO_ONLY,
              label: SettingsListItemsEnum.AUDIO_ONLY,
              value: forcedAudioOnly,
              onSwitch: handleAudioOnlySwitch,
              testId: 'playerSettingsAudioOnlyContainer',
            },
          ]
        : []),
    ];
    return {
      key: 'Nastavení',
      label: 'Nastavení',
      valuesList: baseItems,
    };
  }, [
    audios,
    availableResolutionsList,
    forcedAudioOnly,
    handleAudioOnlySwitch,
    isChromecastSession,
    isNewPlaylist,
    onAudioTrackSelected,
    onPlaybackRateSelected,
    onResolutionSelectedFactory,
    onTextTrackSelected,
    playbackRates,
    playerVariant,
    subtitlesNew,
  ]);

  const captionsStyle = {
    captionSize: {
      key: SettingsListItemsEnum.SUBTITLES_STYLE,
      label: SettingsListItemsEnum.SUBTITLES_STYLE,
      valuesList: [
        {
          key: CaptionFontSize.small,
          label: 'Malé',
          active: captionFontSize === CaptionFontSize.small,
        },
        {
          key: CaptionFontSize.default,
          label: 'Střední',
          active: captionFontSize === CaptionFontSize.default,
        },
        {
          key: CaptionFontSize.large,
          label: 'Velké',
          active: captionFontSize === CaptionFontSize.large,
        },
      ],
      onSelect: onCaptionsSizeSelected,
      testId: 'playerSettingsSubtitlesStyleContainer',
    },
    captionColor: {
      key: SettingsListItemsEnum.SUBTITLES_STYLE,
      label: SettingsListItemsEnum.SUBTITLES_STYLE,
      valuesList: [
        {
          key: CaptionColorVariant.default,
          label: 'Aa',
          active: captionColorVariant === CaptionColorVariant.default,
          customClass: CaptionColorVariant.default,
        },
        {
          key: CaptionColorVariant.yellowText,
          label: 'Aa',
          active: captionColorVariant === CaptionColorVariant.yellowText,
          customClass: CaptionColorVariant.yellowText,
        },
        {
          key: CaptionColorVariant.noBackground,
          label: 'Aa',
          active: captionColorVariant === CaptionColorVariant.noBackground,
          customClass: CaptionColorVariant.noBackground,
        },
        {
          key: CaptionColorVariant.blackText,
          label: 'Aa',
          active: captionColorVariant === CaptionColorVariant.blackText,
          customClass: CaptionColorVariant.blackText,
        },
      ],
      onSelect: onCaptionsColorSelected,
      testId: 'playerSettingsSubtitlesStyleContainer',
    },
  };

  const [activeItem, setActiveItem] = useState<string | SettingsListItemsEnum>(DEFAULT_TITLE);
  const [listContent, setListContent] = useState<SettingsListItemProps>(settingsListItems);

  const isInRoot = activeItem === DEFAULT_TITLE;
  const isCaptionsStyle = activeItem === SettingsListItemsEnum.SUBTITLES_STYLE;

  // aktualizujeme aktualne zobrazeny list podle nove dorazivsich settingsListItems
  useEffect(() => {
    const currentList = settingsListItems.valuesList.find((item) => item.label === activeItem);
    if (!currentList) {
      return;
    }
    // pokud se liší active, updatni
    if (
      currentList.valuesList?.find((item) => item.active) !==
      listContent.valuesList?.find((item) => item.active)
    ) {
      setListContent(currentList);
    }
  }, [activeItem, listContent.valuesList, settingsListItems]);

  const onClickBack = (e?: MouseEvent<HTMLButtonElement>) => {
    if (isCaptionsStyle) {
      setActiveItem(SettingsListItemsEnum.SUBTITLES);
      setListContent(captionsStyle.captionSize);
    } else {
      setActiveItem(DEFAULT_TITLE);
      setListContent(settingsListItems);
    }
    e?.preventDefault();
    e?.stopPropagation();
  };

  // osklive, ale funguje:
  // kvuli volani callbacku na navrat do hl.menu po zvoleni polozky (musime preklesit nejaktualnejsi list)
  useEffect(() => {
    setListContent(settingsListItems);
    // i kdyz v tomto pripade by to nemelo vadit, radsi pouzijeme primo konkretni hodnoty a ne cely objekt
    // rendery se chovaly predvidatelne, ale testy se zacyklily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playbackRate, selectedAudioTrack, selectedTextTrack, selectedResolution]);

  const HeaderContent = (
    <div className={settingsMenuClassnames.listHeader}>
      {!isInRoot && (
        <button
          aria-label={SETTINGS_MESSAGES.settingsGoBackButtonAriaLabel.defaultMessage}
          className={settingsMenuClassnames.icon}
          type="button"
          onClick={onClickBack}
        >
          <IconChevronLeft />
        </button>
      )}
      <span id="content-header">{activeItem}</span>
    </div>
  );

  return (
    <MenuPopup
      hasLeftSectionContent
      activeItem={activeItem}
      closeButtonAriaLabel={SETTINGS_MESSAGES.settingsCloseButtonAriaLabel.defaultMessage}
      manuPopupContentClassName={settingsMenuClassnames.popupContent}
      menuPopupRef={settingsMenuRef}
      menuPopupTitleAriaLabel={
        isInRoot ? SETTINGS_MESSAGES.settingsTitleAriaLabel.defaultMessage : listContent.label
      }
      name={MenuPopupType.SETTINGS}
      playerRef={playerRef}
      scrollableContent={true}
      setMenuPopupVisible={setMenuPopupVisible}
      titleBarContent={HeaderContent}
      onClose={onClose}
    >
      {!isCaptionsStyle ? (
        <div className={settingsMenuClassnames.settingsListWrapper}>
          <SettingsList
            isInRoot={isInRoot}
            listContent={listContent}
            setActiveItem={setActiveItem}
            setListContent={setListContent}
            testId={listContent.testId}
            onGoBack={onClickBack}
          />
          {isInRoot && (
            <div className={settingsMenuClassnames.linksWrapper}>
              <FaqLink faqUrl={FAQ_URL} playlistOptions={playlistOptions} onClick={onHelpClick}>
                Potíže s přehráváním?
              </FaqLink>
            </div>
          )}
        </div>
      ) : (
        <CaptionsStyleList
          listContent={captionsStyle}
          setActiveItem={setActiveItem}
          setListContent={setListContent}
        />
      )}
    </MenuPopup>
  );
};

export default memo(SettingsMenu);
