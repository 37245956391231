import { Encoder } from '@czechtv/utils';
import {
  NielsenMainContentPayload,
  GemiusMainContentPayload,
  GtmMainContentPayload,
  AdScriptContentPayload,
} from '@czechtv/analytics';
import { PlaylistErrorCodesDeprecated } from '../../components/Error/playlistError/playlistError';
import { AdsConfig } from '../../Providers/Client';
import { DRM } from '../drm';
import {
  ExternalLiveStreams,
  ExternalVastConfig,
  LiveMode,
  Product,
  PromoReport,
} from '../../constants';

export const LEGACY_PLAYLIST_ITEM_BASE = {
  id: '',
  duration: '',
  assetId: '',
  title: '',
  aspect: '',
  gemius: {
    ID: '',
    NAZEV: '',
    TYP: '',
    ZDROJ: '',
    CIL: '',
    KATEGORIE: '',
    GA: '',
  },
};

export const LEGACY_PLAYLIST_SETUP_BASE = {
  title: 'Česká televize',
  description: '',
  gemius: {
    IDENTIFIER: '',
    PREFIX: '',
    HITCOLLECTOR: '',
  },
  vast: {},
};

export type IdType =
  | 'bonus'
  | 'index'
  | 'episode'
  | 'channel'
  | 'ct24'
  | 'externalLiveStreams'
  | undefined;
export type NewVodIdType = 'externalId' | 'mediaId' | 'versionId' | 'indexId' | undefined;
export interface ChangeIdType {
  id: string;
  idType: IdType;
  newVodIdType: NewVodIdType;
}

interface ClientPlaylistSuccessResponse {
  url: string;
}

interface PlaylistStreamSetup {
  adOcean?: AdOceanId;
  description: string;
  duration?: string;
  externalId?: string;
  gemius: {
    HITCOLLECTOR: string;
    IDENTIFIER: string;
    PREFIX: string;
  };
  previewImageUrl: string;
  replayToken?: string;
  targetGroup?: string | null;
  title: string;
  vast?: AdsConfig;
}

interface PlaylistStreamIndex {
  imageURL: string;
  time: number;
  title: string;
}

interface NewPlaylistStreamIndex extends Omit<PlaylistStreamIndex, 'imageURL'> {
  imageUrl: string;
}

export interface PlaylistStreamSubtitles {
  code: string;
  title: string;
  url: string;
}

interface LegacyPlaylistResponse {
  aspect: string;
  assetId: string;
  duration: string;
  gemius: {
    CIL: string;
    GA: string;
    ID: string;
    KATEGORIE: string;
    NAZEV: string;
    TYP: string;
    ZDROJ: string;
  };
  id: string;
  previewImageUrl: string;
  previewTrackBaseUrl: string;
  title: string;
}

interface LegacyPlaylistVodResponse extends LegacyPlaylistResponse {
  indexes?: PlaylistStreamIndex[];
  streamUrls: {
    audioDescription?: string;
    main: string;
  };
  subtitles?: PlaylistStreamSubtitles[];
  type: 'VOD' | 'TRAILER';
}

interface LegacyPlaylistLiveResponse extends LegacyPlaylistResponse {
  streamUrls: {
    main: string;
    timeshift: string;
  };
  type: 'LIVE';
}

interface PlaylistAnalytics {
  adScript: AdScriptContentPayload;
  gemius: GemiusMainContentPayload;
  gtm: GtmMainContentPayload;
  nielsen: NielsenMainContentPayload;
}

export interface LegacyPlaylistSuccessResponse {
  analytics?: PlaylistAnalytics;
  playlist: (LegacyPlaylistVodResponse | LegacyPlaylistLiveResponse)[];
  setup: PlaylistStreamSetup;
}

export interface LegacyPlaylistErrorResponse {
  error: string;
  error_description: string;
}

export type ClientPlaylistResponse = ClientPlaylistSuccessResponse;
export type PlaylistStreamResponse = LegacyPlaylistSuccessResponse | LegacyPlaylistErrorResponse;

interface BasePlaylistOptions {
  accessToken?: string;
  adOceanCustomTargeting?: string[];
  adOceanSections?: string[];
  additionalAdKeywords?: string;
  adminMode?: boolean;
  bypassGeoIp?: boolean;
  bypassLicenseToken?: string;
  disableLabeling?: boolean;
  endTimestamp?: number;
  externalEpisodeTitle?: string;
  externalLiveStreams?: ExternalLiveStreams;
  externalShowTitle?: string;
  fairplayAccessToken: string;
  fairplayLicenseCertificateUrl: string;
  fairplayLicenseServerUrl: string;
  faqReportApiUrl?: string;
  forceAudioOnly?: { audioOnly: boolean; isExternalAudioDescription: boolean };
  isLab?: boolean;
  liveMode?: LiveMode;
  logFallbackError?: boolean;
  maxAutoQuality?: number;
  maxQuality?: number;
  noGapTimeshift?: boolean;
  origin?: string;
  playlistLiveUri?: string;
  playlistUri: string;
  playlistVodUri?: string;
  product: Product;
  promoHash?: string;
  promoTimeout?: number;
  replayToken?: string;
  showId?: string;
  skip?: boolean;
  startTimestamp?: number;
  useLegacyPlaylistFallback?: boolean;
  useNewAds?: boolean;
  useNewCdnForLiveIdec?: boolean;
  useNewPlaylist?: boolean;
  usePlayability?: boolean;
  vastConfig?: ExternalVastConfig | null;
  widevineAccessToken: string;
  widevineLicenseServerUrl: string;
}

interface NewLivePlaylistData {
  adOcean: AdOceanId;
  analytics?: PlaylistAnalytics;
  id: number;
  previewImageUrl: string;
  previewTrackBaseUrl: string;
  streamUrls: {
    isBlocked: boolean;
    main: string;
    timeshift: string;
  };
  title?: string;
}

interface SubtitleFile {
  format: string;
  url: string;
}

interface NewVodSubtitle {
  files: SubtitleFile[];
  language: string;
}

interface NewVodPlaylistStream {
  audioDescriptionUrl: string | null;
  chapters: NewPlaylistStreamIndex[];
  duration: number;
  subtitles: NewVodSubtitle[];
  url: string;
}

export interface NewVodPlaylistData {
  adOcean: AdOceanId;
  analytics: PlaylistAnalytics;
  duration: number;
  externalId: string | null;
  mediaType: 'video' | 'audio';
  pegiRating: number | null;
  pegiRatingLink: string | null;
  previewImageUrl: string;
  previewTrackBaseUrl: string;
  programmeTypeEBU: string;
  replayToken?: string;
  streams: NewVodPlaylistStream[];
  targetGroup: string | null;
  title: string;
  vast?: AdsConfig | null;
}

interface NewPlaylistDataDeprecatedError {
  error: PlaylistErrorCodesDeprecated;
  message: string;
}

export interface PlaylistErrorData extends PlayabilityErrorLabels {
  errorStatusName: string;
  streamType: string;
  videoId: string;
}

export interface PlayabilityErrorLabels {
  additionalText?: string;
  button: string;
  textLong: string;
  textShort: string;
  textTvp?: string;
}

export interface PlayabilityErrorStatus {
  expiresAt: string | null;
  name: string;
  params: string | null;
}

interface PlayabilityErrorMessage {
  isPlayable: boolean;
  labels: PlayabilityErrorLabels;
  nextStatus: string | null;
  playableInterval: string | null;
  status: PlayabilityErrorStatus;
}

export interface NewPlaylistDataError {
  message: PlayabilityErrorMessage;
}

export interface NewVodIsLiveData {
  expectedDuration: number;
  idec: string;
  platformChannel: Encoder;
  terrestrialStart: string;
}

export type PlaylistDataError = NewPlaylistDataDeprecatedError | NewPlaylistDataError;

export type NewLivePlaylist = NewLivePlaylistData | NewPlaylistDataDeprecatedError;
export type NewVodPlaylist = NewVodPlaylistData | NewVodIsLiveData | PlaylistDataError;

export interface PlaylistIds {
  bonusId?: string;
  encoder?: Encoder;
  externalId?: string;
  indexId?: string;
  mediaId?: string;
  versionId?: string;
}

export interface AdOceanId {
  postRollsId: string;
  preRollsId: string;
  preRollsLiveId: string;
}

export type PlaylistOptions = {
  cropEnd?: number;
  cropStart?: number;
} & BasePlaylistOptions &
  PlaylistIds;

export type GetPlaylistOptions = PlaylistOptions & {
  supportedDRMs: DRM[];
};
interface BasePlaylistData {
  analytics: PlaylistAnalytics;
  assetId: string;
  drm?:
    | {
        widevineLicenseServerUrl: string;
      }
    | {
        fairplayLicenseCertificateUrl: string;
        fairplayLicenseServerUrl: string;
      };
  id: string;
  indexes?: {
    imageUrl: string | null;
    indexId: string;
    startTime: number;
    stopTime: number;
    title: string | null;
  }[];
  meta: {
    duration: number | null;
    hasAds?: boolean;
    previewImageUrl?: string;
    showTitle: string | null;
    title: string | null;
  };
  previewTrackBaseUrl?: string;
  replayToken?: string;
  subtitles?: {
    code: string;
    title: string | null;
    url: string;
  }[];
  vast?: AdsConfig;
}

export interface PlaylistData extends BasePlaylistData {
  encoder: Encoder | null;
  isLab?: boolean;
  streamUrls: {
    audioDescription?: string | null;
    isLabeling?: boolean;
    main: string;
    promoReport?: PromoReport;
    timeshift?: string;
  }[];
  type: 'vod' | 'live';
  useNewPlaylist: boolean;
}

export interface GetLegacyStreamDataParams {
  cropEnd?: number;
  cropStart?: number;
  disableLabeling?: boolean;
  id: string;
  logFallbackError?: (id: string) => void;
  origin?: string;
  playlistUri: string;
  streamType: 'hls' | 'dash';
  supportedDRMs: DRM[];
  type: 'bonus' | 'index' | 'episode' | 'channel' | 'ct24';
}

export interface GetLiveStreamDataParams {
  encoder: Encoder;
  endTimestamp?: number;
  liveMode?: LiveMode;
  origin?: string;
  playlistUrl: string;
  quality: string;
  sessionId: string;
  startTimestamp?: number;
  streamType: 'hls' | 'dash';
  supportedDRMs: DRM[];
}

export interface GetVodStreamDataParams {
  accessToken?: string;
  bypassGeoIp?: boolean;
  bypassLicenseToken?: string;
  cropEnd?: number;
  cropStart?: number;
  disableLabeling?: boolean;
  id: string;
  origin?: string;
  playlistUrl: string;
  quality: string;
  replayToken?: string;
  sessionId: string;
  streamType: 'hls' | 'dash';
  supportedDRMs: DRM[];
  usePlayability?: boolean;
}

export const NIELSEN_MAIN_CONTENT_EMPTY = {
  assetid: '', // ‘cz-509218-’ + playlistItem.id
  program: '', // playlistItem.title
  title: '', // playlistItem.gemius.NAZEV
  length: '0', // playlistItem.duration
  airdate: '19700101 00:00:01', // nezname - konstanta
  isfullepisode: 'y',
  crossId1: '',
  nol_c1: 'p1,',
  nol_c2: 'p2,',
  type: 'content',
  mediaURL: '',
  segB: '',
  segC: '',
  adloadtype: '2', // 2
  hasAds: '1', // 1 = podporuje reklamy
};

export const GEMIUS_MAIN_CONTENT_EMPTY = {
  programDuration: -1,
  programId: '',
  programName: '',
  programType: 'video',
};

export const GTM_MAIN_CONTENT_EMPTY = {
  id: '',
  title: '',
  live: false,
  show: {
    id: '',
    title: '',
  },
};

export const ADSCRIPT_MAIN_CONTENT_EMPTY = {
  assetid: NIELSEN_MAIN_CONTENT_EMPTY.assetid,
  attribute: '1',
  type: 'content',
  program: NIELSEN_MAIN_CONTENT_EMPTY.program,
  title: NIELSEN_MAIN_CONTENT_EMPTY.title,
  crossId: NIELSEN_MAIN_CONTENT_EMPTY.crossId1,
  length: NIELSEN_MAIN_CONTENT_EMPTY.length,
  livestream: '0',
  channelId: '',
};

export const DEFAULT_PROMO_TIMEOUT = 60;

export const PEGI_RATING_DATA = {
  PEGI_DURATION: '12',
  PEGI_15: {
    rating: 15,
    contentId: '61924494877035680',
    assetId: 'BO-20887',
    title: 'Varování 15+',
    gemiusTitle: 'varovani-15',
  },
  PEGI_18: {
    rating: 18,
    contentId: '61924494878196901',
    assetId: 'BO-15275',
    title: 'Varování 18+',
    gemiusTitle: 'varovani-18',
  },
};

export const CT24_NIELSEN_PROGRAM = 'Obsah zpravodajských portálů';
