export const getTestVodStreamUrls = ({
  streamType,
  origin,
  quality,
}: {
  origin?: string;
  quality?: string;
  streamType: 'dash' | 'hls';
}) => {
  if (!origin) {
    return null;
  }
  if (origin === 'lab_video_only') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=26c86368d5df0f435a50e2216117f72e8cc03756&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263872&streamType=dash&quality=1080p&contentType=vod&region=1&expiry=1730315214&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=9d2d532297d1cb416a1cfa73e56958f14a0be925&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263872&streamType=hls&quality=1080p&contentType=vod&region=1&expiry=1730315214&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
    };
  }
  if (origin === 'lab_vod_112_english') {
    if (streamType === 'dash') {
      if (quality === 'audio') {
        console.log('audio_only_lab_vod_112_english');
        return {
          main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=e1bb653615947ba6e47e495d547fe193b74776ef&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=dash&quality=audio&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        };
      }
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=2a3655c7ac68a329deac624d538d504b80084082&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=dash&quality=1080p&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      };
    }
    if (quality === 'audio') {
      console.log('audio_only_lab_vod_112_english');
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=0027a0485c5256af891ddc830f2ac27c5f6c8cbd&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=hls&quality=audio&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=8c4b5a1ce5d84b229a38ee63de2b52bd519132ad&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=hls&quality=1080p&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
    };
  }
  return null;
};

export const getTestLiveStreamUrls = (streamType: 'dash' | 'hls', origin?: string) => {
  if (!origin) {
    return null;
  }
  if (origin === 'lab_122') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=30b294e450a738795104dc177f98375cafaa3f76&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1733400180&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=b4f20bc51d920884bdf28c793786559e294ecad5&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1733400180&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=d546c70738ef7a4db80852f67cb6aa84359c0e57&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1733400180&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=e51e16689c9c084932c4e5dd5a9700b3b0b3a2ac&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1733400180&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      isBlocked: false,
    };
  }
  if (origin === 'lab_102') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=a2e09449335dbe430d18e353cb2cd471a55ae6db&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1733400180&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=c95269d839823501841e2d55ae85d8382c80779f&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1733400180&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=f3cdade712e9047066918109274ca7ea49b4141f&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1733400180&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=38e339bac46136942b59df818df920b87aeb8b9d&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1733400180&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      isBlocked: false,
    };
  }
  return null;
};
